body{
   background-color: orange;
   /* background: rgb(34,193,195);
    background: -moz-radial-gradient(circle, rgba(34,193,195,1) 0%, rgba(224,255,238,0) 100%);
    background: -webkit-radial-gradient(circle, rgba(34,193,195,1) 0%, rgba(224,255,238,0) 100%);
    background: radial-gradient(circle, rgba(34,193,195,1) 0%, rgba(224,255,238,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#22c1c3",endColorstr="#e0ffee",GradientType=1); 
    */
    min-width: 300px;
}

.maincontent{ 
    width: 90%;
    position: relative;
    margin: auto;
    border-style: 0px dotted black;
}

.navbar{
    background: rgb(2,0,36);
    background: -moz-linear-gradient(191deg, rgba(2,0,36,1) 0%, rgba(18,18,103,1) 35%, rgb(16, 22, 24) 100%);
    background: -webkit-linear-gradient(191deg, rgba(2,0,36,1) 0%, rgba(18,18,103,1) 35%, rgba(16, 22, 24) 100%);
    background: linear-gradient(191deg, rgba(2,0,36,1) 0%, rgba(18,18,103,1) 35%, rgba(16, 22, 24) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#00d4ff",GradientType=1); 
    display: flex;
    justify-content: space-around;
    opacity: 1;
    z-index: 2;
    left: 15%;
    width: 80%;
    position: fixed;
    border-radius: 0px;
    opacity:0.98;   
    height: 54.7px;
}

.nav-links{
    /*min-height: 8vh;*/
    display: flex;
    width: 80%;
    justify-content: space-around;
    list-style: none;
    color: rgb(117, 108, 108);
}

.nav-links li:hover{
    border-bottom: 3px solid orange;
    max-height:18px;
}

.togglelinks:hover{
    border-bottom: 2px solid orange;
    max-height:19px;
}

#onetext{
    color: seagreen;
    margin-top: 150px;
    width: 80%;
    height: 100px;
    display:flex;
    margin-left: auto;
    margin-right: auto;
}

.foot{
    position:fixed;
    bottom:0;
    width:100%;
    max-height: 40px;
    background-color:whitesmoke; 
    backdrop-filter: blur(5px);
    opacity: 0.75;

    /*
    background: rgb(2,0,36);
    background: -moz-linear-gradient(191deg, rgba(2,0,36,1) 0%, rgba(18,18,103,1) 35%, rgba(0,212,255,1) 100%);
    background: -webkit-linear-gradient(191deg, rgba(2,0,36,1) 0%, rgba(18,18,103,1) 35%, rgba(0,212,255,1) 100%);
    background: linear-gradient(191deg, rgba(2,0,36,1) 0%, rgba(18,18,103,1) 35%, rgba(0,212,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#00d4ff",GradientType=1); 
    */
    display: flex;
    justify-content: space-around;    
}

.foot p{
    color: black;
}