body {
  background-color: lightblue;
  min-width: 250px;
}

.main{
  width: 108%;
  position:relative;
  margin:auto;
  text-align: center;
}

.maincontent{
  position: relative;
}

nav.navbar{
  width: 100%;
  left: 0;
  top:0;
  position: absolute;
  transition: all 7s; 
  opacity: unset;
}

div.navbar{
  display: none;
}

div#navbar{
  display: none;
}

ul.navbar-burger{
  display: none;
}

.navbar-burger.expanded{
  position: absolute;
  display: grid;
  background-color: darkorange;
  width: 100%;
  top: 39px;
  opacity: 0.75;
  right:0;
}

.navbar-burger.expanded a{
  cursor: pointer;
  text-decoration: none;
  color:whitesmoke;
  margin: auto;
  font-size: 2rem;
}

.navbar-burger a:hover{
  color: black;
  background-color: beige;
  width: 100%;
  margin: auto;
  text-align: center;
  left:0;
  position: relative;
}

.navbar-burger a:active{
  color: whitesmoke;
  background-color: blueviolet;
}

.products{
  display: block;
}

@media screen and (min-width: 900px) {
  .products{
    display: flex;
    transition: all;
    animation-delay: 1.9s;
  }
}

@media screen and (min-width: 80px) {
  
  body{
    background-color: aqua;
  }

  .main{
    width: 100%;
    position: relative;
    margin: auto;
    margin-bottom: auto;
    text-align: center;
    margin-bottom: 15px;
  }

  .maincontent{
    position: absolute;
    border-radius: 5px;
  }

  nav.navbar{
    display: none;
    transition: all 5.5s;
  }

  div.navbar{
    display: flex;
    min-width: 250px;
    transition: all 7s;  
  }
    
  div#navbar{
    display: flex;
  }    
} 
@media screen and (min-width: 0px) {
  
}