
.describes{
    color:whitesmoke;
    margin-bottom: 2px;
    padding-left: 15px;
    padding-right:15px;
    border-radius: 15px;
    opacity: 1;
    background-color: rgb(2,0,36);
    background: linear-gradient(191deg, rgba(2,0,36,1) 0%, rgba(18,18,103,1) 35%, rgba(16, 22, 24) 100%);
    text-align: justify;
}
